.App {
  text-align: center;
}

.App-logo {
  height: 30vmin;
  pointer-events: none;
  padding: 3vmin;
  margin: 7vmin;
  /* filter: invert(38%) sepia(76%) saturate(6005%) hue-rotate(274deg) brightness(94%) contrast(110%); */
  background-color: aquamarine;
}

.nav-logo {
  height: 5vmin;
  pointer-events: none;
  padding: 1vmin;
}

.nav-item {
  padding: 5px;
  color: #fff;
}

.nav-item:hover {
  color: #fff;
  background-color: lightgray;
}

.nav-logo {
  filter: invert(99%) sepia(0%) saturate(218%) hue-rotate(205deg) brightness(117%) contrast(87%);
}

.navbar-toggler {
  background-color: brown;
}

.nav-item:hover .nav-logo {
  filter: none;
  animation: App-logo-spin infinite 1s linear;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 4s linear;
  }
}

.App-header {
  background-color: #282c34;
  /* background-color: magenta; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-header p {
  font-size: calc(10px + 1vmin);
  margin-left: 10vmin;
  margin-right: 10vmin;
}

.cheeky-text {
  font-size: calc(5px + 2vmin);
  color: grey;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


#details {
  height: 100%;
}

#root {
  height: calc(100vh - 70px);
}