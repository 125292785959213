.map-button-container {
    padding-top: 10px;
}

.map-form {
    padding: 1rem;
    flex-basis: 250px;
    flex-shrink: 1;
    height: 100%;
    overflow: auto;
    max-width: 33%;
}

.map-form input {
    width: 100%;
}

.map-button-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%;
}

.map-button-container button {
    display: flex;
    text-align: center;
    margin: .2em;
}